$(function() {
  $("#loadingDIV").hide();
  $("#privacyDIV").hide();
  $("#menuDIV").hide();
  $("#myModal").hide();

  try {
    panzoom_init();
  } catch(err) { }

  var language = getQueryVariable("qr");
  if(language != false) { language_switch(language); }
});

$(document).on('click','.appointment, #appointmentDIV button, .set_appointment', function(event) {
  event.stopPropagation();

  location.href = "../cita.html";
});

$(document).on('click','.flag', function() {
  var language = $(this).attr("data-language");

  language_switch(language);
});

$(document).on('click','.button_search', function(event) {
  $("#myModal").fadeIn();
});

$(document).on('click','#contactBTN', function(event) {
  var contact_name = getInputValues("#contact_name");
  var contact_email = getInputValues("#contact_email");
  var contact_phone = getInputValues("#contact_tel");
  var contact_business = getInputValues("#contact_business");
  var contact_website = $(this).data("website");
  var user_data = {
    "name": contact_name["val"],
    "email": contact_email["val"],
    "phone": contact_phone["val"],
    "business": contact_business["val"],
    "web": contact_website
  }

  if(contact_name["val"] == "") {
    show_message("Favor de llenar el campo de Nombre");
    contact_name["input"].focus();
    return false;
  }

  if(contact_phone["val"] == "") {
    show_message("Favor de llenar el campo de Teléfono");
    contact_phone["input"].focus();
    return false;
  }

  if(contact_email["val"] == "") {
    show_message("Favor de llenar el campo de Correo");
    contact_email["input"].focus();
    return false;
  }

  $.ajax({
    url: "../php/mail_sender.php",
    method: "POST",
    data: {
      user_data: user_data,
    },
    dataType: "json",
  })
  .done(function (jsonp) {
    show_message("Enviada Correctamente", "success");
    contact_name["input"].val("");
    contact_email["input"].val("");
    contact_phone["input"].val("");
    contact_business["input"].val("");
  })
  .fail(function (err) {
    console.log("ERR");
    console.log(err);
  });
});

function panzoom_init() {
  const website = $("body").attr("id");
  const element = document.getElementById("panzoom");
  const element2 = document.getElementById("panzoom2");
  const panzoom = Panzoom(element, {});
  const panzoom2 = Panzoom(element2, {});
  const parent = element.parentElement;
  const parent2 = element2.parentElement;
  
  parent.addEventListener("wheel", panzoom.zoomWithWheel);
  parent2.addEventListener("wheel", panzoom2.zoomWithWheel);

  if(website == "montoro") {
    setTimeout(() => {
      panzoom.zoom(0.4, { animate: true });
      panzoom.pan(-280, -540);
      panzoom2.pan(0, 0);
    }, 500);
  }
  
  if(website == "oportunidad") {
    setTimeout(() => {
      panzoom.zoom(1, { animate: true });
      panzoom.pan(0, 0);
      panzoom2.pan(0, 0);
    }, 500);
  }

  if(website == "rsa") {
    setTimeout(() => {
      panzoom.zoom(0.8, { animate: true });
      panzoom.pan(-500, 0);
      panzoom2.pan(-500, 150);
    }, 500);
  }

  if(website == "estadosunidos") {
    setTimeout(() => {
      panzoom.zoom(1, { animate: true });
      panzoom.pan(0, 0);
      panzoom2.pan(0, 0);
    }, 500);
  }
}

function change_panzoom(value, zoom = 0) {
  const element = document.getElementById("panzoom");
  const element2 = document.getElementById("panzoom2");
  const panzoom = Panzoom(element, {});
  const panzoom2 = Panzoom(element2, {});

  panzoom.zoom(zoom, { animate: true });
  panzoom.pan(value, 0);
  panzoom2.pan(value, 0);
}

function language_switch(lang) {
  $.ajax({
      url: 'locale/language.json',
      method: 'GET',
      dataType: 'json'
  }).done(function(json) {
      var toTranslate = $("[data-i18n-key]");

      $.each(toTranslate, function(index, item) {
          var dataKey = $(item).attr("data-i18n-key");
          
          if($(item).is("input")) {
              $(item).attr("placeholder",json[lang][dataKey]);
          } else {
              $(item).html(json[lang][dataKey]);
          }
      });
  });
}