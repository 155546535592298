$(function() {
    $("#loadingDIV").hide();
    $("#privacyDIV").hide();
    $("#menuDIV").hide();
    $("#myModal").hide();
});

$(document).on('click','.appointment', function(event) {
    event.stopPropagation();

    location.href = "cita.html";
});