$(document).on('click','.menu span', function(event) {
    event.preventDefault();

    $("#menuDIV").slideDown();
    $("body").css("overflow","hidden");
});

$(document).on('click','#menuDIV a', function(event) {
    event.preventDefault();

    $("#menuDIV").slideUp();
    $("body").css("overflow","visible");
});

$(document).on('click','#privacyDIV .close_btn', function(event) {
    event.preventDefault();
    
    $("#privacyDIV").fadeOut();
});

$(document).on('click','#whatsappBTN, #whatsappBLK', function(event) {
    var url = "https://wa.me/+524491894026?text=Quisiera%20informes%20sobre%20sus%20Proyectos";
	window.open(url);
});

$(document).on('click','#privacyBTN a', function(event) {
    event.preventDefault();

    privacyDIV_show($(this).data("relative"));
});

$(document).on('click','.modal-body>a', function(event) {
    event.preventDefault();

    $("#myModal").fadeOut();
});

$(document).on('click', '#menuAppointment #logo', function (event) {
    event.preventDefault();

    var url = location.host;

    if(url.indexOf("ranchosanantonio") >= 0) {
        location.href = "https://www.ranchosanantonio.mx";
    } 
    
    if(url.indexOf("oportunidad") >= 0) {
        location.href = "https://www.oportunidadorienteags.com";
    }
    
    if(url.indexOf("montoro") >= 0) {
        location.href = "https://www.montoro.mx";
    } 

    if(url.indexOf("5suelos") >= 0) {
        location.href = "https://www.5suelos.com";
    }
});

$(document).on('keyup', '.onlyNumbers', function () {
    var value = $(this).val();
    var lastDigit = value.substr(value.length - 1);

    if (isNaN(parseInt(lastDigit)) == true && lastDigit != ".") {
      value = value.substr(0, value.length - 1);
    }

    if(lastDigit == "." && !$(this).hasClass("currencyFormat")) {
      value = value.substr(0, value.length - 1);
    }

    $(this).val(value);
});

$(document).on('change', '.onlyNumbers', function () {
    var value = $(this).val();

    if ($(this).hasClass("currencyFormat")) {
        var format = accounting.formatMoney(value, "");
        $(this).val(format);
    } else {
        $(this).val(value);
    }
});

function loadingDIV_show(fixed = false) {
    $("#loadingDIV").addClass("active").fadeIn();

    if(fixed == false) {
        setTimeout(function() {
            loadingDIV_hide();
        },8000);
    }    
}

function loadingDIV_hide() {
    $("#loadingDIV").addClass("active").fadeOut();
}

function show_message(message, type = "danger", hide = 6000) {
    const info_div = $("#infoDIV");
    const message_div = `
        <div class="alert-${type}" role="alert">
            <span><i></i></span>
            <p>${message}</p>
        </div>`;
    var message_added;

        info_div.prepend(message_div);
    
        message_added = info_div.children().first();
    message_added.animate({
        left: 0
    });
    setTimeout(function () {
        message_added.animate({
            left: "300px"
        }, function () {
            $(this).remove()
        });
    }, hide);
}

function privacyDIV_show(relative = false) {
    const privacyDIV = $("#privacyDIV");
    const privacyTitle = privacyDIV.find("h1").length;
    const subfolder = (relative) ? "../" : "";
    const file_route = subfolder + "avisodeprivacidad.html"

    if(privacyTitle == 0) {
        $.ajax({
            url: file_route,
            method: "GET",
            dataType: "html"
        }).done(function(html) {
            privacyDIV.html($(html).find("#privacyBody")).fadeIn();
        });        
    } else {
        privacyDIV.fadeIn();
    }
}

function getQueryVariable(variable) {
    var query = window.location.search.substring(1);
    var vars = query.split("&");
    var pair;
  
    for (var i = 0; i < vars.length; i++) {
      pair = vars[i].split("=");
  
      if (pair[0] == variable) {
        return pair[1];
      }
    }
  
    return false;
}

function getInputValues(input, trimmed = true) {
    var input = $(input);
    var obj = {
      "input": input,
      "val": (trimmed) ? $.trim(input.val()) : input.val(),
      "text": input.text()
    }
  
    if (input.hasClass("toLower")) {
      obj["val"] = obj["val"].toLowerCase();
    }
  
    if (input.hasClass("toUpper")) {
      obj["val"] = obj["val"].toUpperCase();
    }
    if (input.hasClass("toLower")) {
      obj["val"] = obj["val"].toLowerCase();
    }
  
    if (input.hasClass("toUpper")) {
      obj["val"] = obj["val"].toUpperCase();
    }
  
    return obj;
}